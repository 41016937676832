.Feature {
    .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    img {
        background-size: cover;
    }

    .test {
        width: 51%;
        height: 51%;
    }

    .title {
        font: var(--unnamed-font-style-normal) normal bold var(--unnamed-font-size-24)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-26415d);
        font: normal normal bold 18px/0px Roboto;
        letter-spacing: 0px;
        color: #26415D;
        opacity: 1;
        width: 100%;
        text-align: center;
    }

    .subtitle {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ababab);
        text-align: center;
        font: normal normal 300 18px/30px Roboto;
        letter-spacing: 0px;
        color: #ABABAB;
        opacity: 1;
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;
    }
}