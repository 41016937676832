.sectionHeader {
    background-image: url('../../../images/bg.svg');
    background-size: cover;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 30px;

    .title {
        font: var(--unnamed-font-style-normal) normal normal 43px/60px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-26415d);
        text-align: center;
        font: 43px/60px Roboto;
        margin: 0;
        letter-spacing: 0px;
        color: #26415D;
        white-space: pre-line;
        opacity: 1;
    }

    .textDownload {
        font: var(--unnamed-font-style-normal) normal bold 14px/10px Roboto Mono;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal bold 14px/10px Roboto Mono;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

    #img_1 {
        margin-right: 15px;
    }

    #img_2 {
        margin-left: 15px;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .flag {
        flex-wrap: wrap;
        justify-content: center;
    }
}