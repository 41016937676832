.SectionRegister {
    .container {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .flex2 {
        flex: 2,
    }

    .flex1 {
        flex: 1
    }

    .subTitle {
        font: var(--unnamed-font-style-normal) normal bold 30px/0px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-26415d);
        font: normal normal bold 30px/0px Roboto;
        letter-spacing: 0px;
        color: #26415D;
        opacity: 1;
        text-align: center;
    }

    .title {
        font: var(--unnamed-font-style-normal) normal bold 55px/0px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ff80d9);
        font: normal normal bold 55px/55px Roboto;
        letter-spacing: 0px;
        color: #FF80D9;
        opacity: 1;
        text-align: center;
    }

    .content {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ababab);
        text-align: left;
        font: normal normal 300 20px/30px Roboto;
        letter-spacing: 0px;
        color: #ABABAB;
        opacity: 1;
        padding-left: 16px;
        padding-right: 16px;
    }

    .contentRegister {}
}