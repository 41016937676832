.SectionFeature {
    padding: 0px 32px;

    .line {
        border-bottom: 6px solid #FF80D9;
        border-radius: 40px;
        opacity: 1;
        width: 150px;
        margin-bottom: 30px;
        height: 0px;
    }

    .title {
        font: var(--unnamed-font-style-normal) normal bold 30px/0px (--unnamed-line-spacing-40) var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-26415d);
        font: normal normal bold 30px/30px Roboto;
        letter-spacing: 0px;
        color: #26415D;
        text-align: left;
    }

    .titleContent {
        font: var(--unnamed-font-style-normal) normal bold 30px/0px (--unnamed-line-spacing-40) var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-26415d);
        font: normal normal bold 30px/30px Roboto;
        letter-spacing: 0px;
        color: #26415D;
        text-align: center;
    }

    .rows {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .feature {
        margin-top: 80px;
        margin-bottom: 80px;
    }

}