.label {
    font: var(--unnamed-font-style-normal) normal bold 14px/10px Roboto Mono;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    font: normal normal bold 18px/16px Roboto Mono;
    letter-spacing: 0px;
    padding-left: 100px;
    padding-right: 100px;
    color: #FFFFFF;
}

#btn_download {
    background: var(--unnamed-color-ff80d9) 0% 0% no-repeat padding-box;
    background: #FF80D9 0% 0% no-repeat padding-box;
    box-shadow: 0px 14px 6px #FF58C729;
    border-radius: 100px;
    border: none;
    outline: none;
    margin: 30px 16px;
}

#btn_square {
    background: var(--unnamed-color-ff80d9) 0% 0% no-repeat padding-box;
    background: #FF80D9 0% 0% no-repeat padding-box;
    box-shadow: 0px 14px 6px #FF58C729;
    border-radius: 8px;
    border: none;
    width: 100%;
    outline: none;
    margin: 30px 0px;
}

#btn_square:hover {
    opacity: 0.8;
}

#btn_download:hover {
    opacity: 0.8;
}