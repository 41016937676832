p {
    white-space: pre-line;
}

html {
    scroll-behavior: smooth;
}

#mobile_menu {
    display: none;
}

.contructorPage {
    display: none;

    .incontructorImg {
        background-size: contain;
        width: 100%;
    }

    p {
        font: var(--unnamed-font-style-normal) normal normal 23px/30px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-26415d);
        text-align: center;
        font: normal normal normal 23px/30px Roboto;
        letter-spacing: 0px;
        color: #26415D;
        opacity: 1;
        margin: 20px 32px;
    }
}

@media only screen and (max-width: 414px) {
    .sectionHeader #img_1 {
        margin-right: 0 !important;
    }

    .sectionHeader #img_2 {
        margin-left: 0 !important;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 540px) {

    #person1 {
        display: none;
    }

    #person2 {
        display: none;
    }

    #person4 {
        display: none;
    }

    #person5 {
        display: none;
    }

    #mobile_menu {
        display: block;
    }

    #vg_2 {
        display: none;
    }

    #vg_3 {
        display: none;
    }

    #vg_1 {
        display: none;
    }

    .label {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .contructorPage {
        display: none;
    }

    #vg_2 {
        display: none;
    }

    .HeaderHome {
        display: none;
    }

    .Footer .row {
        display: flex !important;
        flex-direction: column !important;
    }

    .underFooter .container .row {
        display: flex !important;
        flex-direction: row !important;
    }

    .Footer .marginLeft {
        margin-left: 0px !important;
    }

    .marginLeft {
        margin-left: 0;
    }

    .ContactBox {
        display: none;
    }

}


@media only screen and (max-width: 600px) {
    #mobile_menu {
        display: block;
    }

    .label {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .contructorPage {
        display: none;
    }

    #vg_2 {
        display: none;
    }

    .HeaderHome {
        display: none;
    }

    .underFooter {
        display: flex;
    }
}

@media only screen and (max-width: 768px) {

    #mobile_menu {
        display: block;
    }

    #vg_1 {
        display: none;
    }

    #vg_2 {
        display: none;
    }

    .contructorPage {
        display: none;
    }

    .HeaderHome {
        display: none;
    }

    #person4 {
        display: none;
    }


}

@media only screen and (max-width: 1024px) {
    .SectionFeatureDetail {
        display: none !important;
    }

    #person5 {
        display: none;
    }

    #mobile_menu {
        display: block;
    }

    .HeaderHome {
        display: none;
    }

    #vg_2 {
        display: none;
    }

    #vg_3 {
        display: none;
    }

    .contructorPage {
        display: none;
    }



}