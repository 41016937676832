.Footer {
    padding: 32px 32px;
    background: var(--unnamed-color-26415d) 0% 0% no-repeat padding-box;
    background: #26415D 0% 0% no-repeat padding-box;

    .Address {
        margin-right: 10px;
    }

    .simple_text {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) 16px/29px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal 300 16px/29px Roboto;
        letter-spacing: 0px;
        color: #93A0AE;
    }

    .title {
        font: var(--unnamed-font-style-normal) normal bold 16px/29px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal bold 16px/29px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

    .flex1 {
        flex: 1,
    }

    .btnSubmit {
        height: 56px;
        margin-top: 28px;
        background: var(--unnamed-color-ff80d9) 0% 0% no-repeat padding-box;
        background: #FF80D9 0% 0% no-repeat padding-box;
        box-shadow: 0px 14px 6px #FF58C729;
        border-radius: 8px;
        width: 307px;
        opacity: 1;
        font: var(--unnamed-font-style-normal) normal bold 16px/20px Roboto Mono;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal bold 16px/20px Roboto Mono;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

    .btnSubmit:hover {
        opacity: 0.8;
    }

    label {
        font: var(--unnamed-font-style-normal) normal normal 16px/29px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal normal 16px/29px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

    input {
        border: 0.1px solid var(--unnamed-color-707070);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.10000000149011612px solid #707070;
        border-radius: 6px;
        height: 38px;
        opacity: 1;
        outline: none;
        padding-left: 10px;
    }

    textarea {
        border: 0.1px solid var(--unnamed-color-707070);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.10000000149011612px solid #707070;
        border-radius: 6px;
        opacity: 1;
        outline: none;
        padding-left: 10px;
        padding-top: 10px;
    }

    .column {
        display: flex;
        flex-direction: column;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .marginLeft {
        margin-left: 40px;
    }

    .marginTop {
        margin-top: 20px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background: transparent 0% 0% no-repeat padding-box;
        border: 0.10000000149011612px solid #707070;
        border: 0.1px solid var(--unnamed-color-707070);
    }

    li {
        float: left;

    }


    .tagSocial {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }

    .tagSocial a {
        opacity: 1;
        margin-right: 0px;
        margin-left: 40px;
    }

    .marginRight12 {
        margin-right: 12px;
    }

    li a {
        display: block;
        color: #FFFFFF;
        text-align: center;
        padding-top: 16px;
        padding-bottom: 16px;
        text-decoration: none;
        margin-right: 96px;
        opacity: 0.5;
    }

    .login {
        align-self: flex-end;
    }

    .containerFlag {
        flex: 1;
        justify-content: flex-end;
    }

    .flag {
        float: right;
        margin-right: 32px;
    }

    .container {
        display: flex;
        align-items: center;
    }


    .line {
        height: 30px;
        border: 1px solid #E2E2E2;
        margin-right: 96px;
    }

    li a:hover {
        opacity: 0.6;
    }

    .row {
        display: flex;
        flex-direction: row;
    }

    .flag img {
        margin-left: 10px;
    }

    .headerContainer {
        margin-right: 0;
    }

    .headerContainer p {
        font: var(--unnamed-font-style-normal) normal normal 19px/25px Lato;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal normal 19px/25px Lato;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

    .marginRight {
        margin-right: 70px;
    }

    .underFooter {
        margin-top: 40px;
        border-top: 0.5px solid var(--unnamed-color-707070);
        border-top: 0.5px solid #707070;
    }
}